.button {
  position: relative;
  z-index: 1;
  cursor: pointer;
  opacity: 1;
  transition: .2s ease-in-out opacity;
}

.inside {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  line-height: 1.2;
  padding: var(--spacing-12) var(--spacing-24);
  border-width: 2px;
  border-style: solid;
  transition: .2s ease-in-out;
  transition-property: background-color, color, border-color, opacity, padding, transform;
  border-radius: var(--radius-xl);
  font-weight: 900;
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* LOADER */

.loader {
  position: absolute;
  z-index: 1;
  left: 10px;
  opacity: 0;
  transition: 0.3s linear 0.3s;
  transition-property: opacity;
}

.isLoading .inside {
  padding-left: calc(var(--spacing-24) + var(--spacing-4) + var(--spacing-16));
}

.isLoading .loader {
  animation: 0.3s linear 0.3s fade-in forwards;
}

.icon, .loader {
  --size-icon: 24px;
  height: var(--size-icon);
  width: var(--size-icon);
}

.icon {
  margin-right: var(--spacing-12);
}

.inside.right {
  flex-direction: row-reverse;
}

.inside.right .icon {
  margin-right: 0;
  margin-left: var(--spacing-12);
}

/* COLORS */

.primary {
  background-color: var(--b-green);
  color: var(--white);
  border-color: var(--b-green);
}
.primary::before, .primary::after {
  border-color: var(--b-green);
}
.primary:not(:disabled):hover {
  border-color: var(--black);
  background-color: var(--black);
}

.secondary {
  background-color: var(--ice);
  color: var(--b-green);
  border-color: var(--ice);
}
.secondary::before, .secondary::after {
  border-color: var(--white);
}
.secondary:not(:disabled):hover {
  background-color: var(--b-green);
  color: var(--white);
}

.dark {
  background-color: var(--black);
  color: var(--ice);
  border-color: var(--black);
}
.dark::before, .dark::after {
  border-color: var(--dark);
}
.dark:not(:disabled):hover {
  background-color: var(--b-green);
  color: var(--white);
}

/* Outline */
.outline .primary, .outline .primary::before, .outline .primary::after {
  background-color: transparent !important;
  border-color: var(--b-green);
  color: var(--b-green);
}
.outline .primary:not(:disabled):hover{
  border-color: var(--black);
  color: var(--black);
  background-color: transparent !important;
}

.outline .secondary, .outline .secondary::before, .outline .secondary::after {
  background-color: transparent;
  border-color: var(--ice);
  color: var(--ice);
}
.outline .secondary:not(:disabled):hover{
  border-color: var(--b-green);
  color: var(--b-green);
  background-color: transparent;
}

.outline .dark, .outline .dark::before, .outline .dark::after {
  background-color: transparent;
  border-color: var(--black);
  color: var(--black);
}
/* ANIMATIONS */

@keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 0.7; }
}